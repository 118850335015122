import "./src/styles/global.css"
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    const recaptchaSiteKey = `${process.env.GATSBY_RECAPTCHA_SITE_KEY}`
    return (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaSiteKey}>
            {element}
        </GoogleReCaptchaProvider>
    )
}