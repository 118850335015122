exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-talents-tsx": () => import("./../../../src/pages/talents.tsx" /* webpackChunkName: "component---src-pages-talents-tsx" */),
  "component---src-templates-project-detail-tsx": () => import("./../../../src/templates/project-detail.tsx" /* webpackChunkName: "component---src-templates-project-detail-tsx" */),
  "component---src-templates-talent-detail-tsx": () => import("./../../../src/templates/talent-detail.tsx" /* webpackChunkName: "component---src-templates-talent-detail-tsx" */)
}

